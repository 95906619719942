var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('q-footer',[_c('div',{staticClass:"row col-12 justify-center footer"},[_c('div',{staticClass:"row col-12"},[_c('div',{staticClass:"col-12 q-pa-xs bg-barra"})]),_c('div',{class:'row col-12 bg-datos-footer ' +
          (_vm.$q.screen.xs ? ' q-pa-sm' : 'q-pa-xl justify-between')},[_c('div',{staticClass:"row col-xs-12 col-sm-6 col-md-3 q-pa-sm"},[_c('div',{staticClass:"col-12"},[_c('img',{staticClass:"img-fluid cursor-pointer",staticStyle:{"height":"auto !important","margin-left":"-15px"},attrs:{"loading":"lazy","src":require("../../assets/logos/logoarica-blanco.webp"),"alt":"Logo Municipalidad de Arica"},on:{"click":function($event){return _vm.routerGo('Home')}}})]),_c('div',{staticClass:"col-12 q-mt-md"},[_c('p',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.toLink('https://goo.gl/maps/zZ6WN2M1doev6ASN6', 1)}}},[_vm._v(" Ilustre Municipalidad de Arica Avenida Rafael Sotomayor #415, Arica. ")]),_c('br'),_c('a',{staticClass:"phone",staticStyle:{"color":"white","font-size":"15px"},attrs:{"href":"tel:432380004"}},[_vm._v("Teléfono: 432380004")])]),_c('div',{staticClass:"col-12 q-mt-md text-bold"},[_vm._v(" VISITA NUESTRAS REDES SOCIALES ")]),_c('div',{staticClass:"row col-12 q-mt-md"},[_c('div',{staticClass:"col-1 q-mx-sm"},[_c('img',{staticClass:"img-fluid cursor-pointer",staticStyle:{"height":"auto !important"},attrs:{"loading":"lazy","src":require("../../assets/Home/Iconos/insta-color.webp"),"alt":"Icono Instagram"},on:{"click":function($event){return _vm.toApp('https://www.instagram.com/muniarica/')}}})]),_c('div',{staticClass:"col-1 q-mx-sm"},[_c('img',{staticClass:"img-fluid cursor-pointer",staticStyle:{"height":"auto !important"},attrs:{"loading":"lazy","src":require("../../assets/Home/Iconos/face-color.webp"),"alt":"Icono Facebook"},on:{"click":function($event){return _vm.toApp('https://www.facebook.com/MunicipalidaddeArica')}}})]),_c('div',{staticClass:"col-1 q-mx-sm q-mt-xs"},[_c('img',{staticClass:"img-fluid cursor-pointer",staticStyle:{"height":"auto !important"},attrs:{"loading":"lazy","src":require("../../assets/Home/Iconos/youtube-color.webp"),"alt":"Icono Youtube"},on:{"click":function($event){return _vm.toApp(
                  'https://www.youtube.com/channel/UCcVtpRl__F8KinypQGhO7VA'
                )}}})]),_c('div',{staticClass:"col-1 q-mx-sm"},[_c('img',{staticClass:"img-fluid cursor-pointer",staticStyle:{"height":"auto !important"},attrs:{"loading":"lazy","src":require("../../assets/Home/Iconos/tiktok-color.webp"),"alt":"Icono tiktok"},on:{"click":function($event){return _vm.win.open(
                  'https://www.tiktok.com/@aricamunicipalidad',
                  '_blank'
                )}}})]),_c('div',{staticClass:"col-1 q-mx-sm q-mt-xs"},[_c('img',{staticClass:"img-fluid cursor-pointer",staticStyle:{"height":"auto !important"},attrs:{"loading":"lazy","src":require("../../assets/Home/Iconos/flicker-color.webp"),"alt":"Icono Flicker"},on:{"click":function($event){return _vm.win.open('https://www.flickr.com/photos/muniarica/', '_blank')}}})])])]),_c('div',{staticClass:"row col-xs-12 col-sm-6 col-md-3 q-pa-sm"},[_c('div',{staticClass:"col-12"},[_c('p',{staticClass:"text-bold text-titulo-footer"},[_vm._v("MUNICIPALIDAD")]),_c('ul',[_c('li',{staticClass:"q-mb-sm cursor-pointer",on:{"click":function($event){return _vm.irPagina('alcalde')}}},[_vm._v(" Alcalde ")]),_c('li',{staticClass:"q-mb-sm cursor-pointer",on:{"click":function($event){return _vm.irPagina('concejo')}}},[_vm._v(" Concejo municipal ")]),_c('li',{staticClass:"q-mb-sm cursor-pointer",on:{"click":function($event){return _vm.toLink(
                  'https://transparencia.municipalidaddearica.cl/page.php?p=8&id=8',
                  1
                )}}},[_vm._v(" Ordenanzas ")]),_c('li',{staticClass:"q-mb-sm cursor-pointer",on:{"click":function($event){return _vm.irPagina('ConcursosPublicos')}}},[_vm._v(" Concursos públicos ")]),_c('li',{staticClass:"q-mb-sm cursor-pointer",on:{"click":function($event){return _vm.irPagina('juzgado-de-policia-local')}}},[_vm._v(" Juzgado de policía local ")]),_c('li',{staticClass:"q-mb-sm cursor-pointer",on:{"click":function($event){return _vm.irPagina('direccion-de-transito-y-transporte-publico')}}},[_vm._v(" Tránsito ")]),_c('li',{staticClass:"q-mb-sm cursor-pointer",on:{"click":function($event){return _vm.irPagina('direccion-de-administracion-y-finanzas')}}},[_vm._v(" Dirección de administración y finanzas ")]),_c('li',{staticClass:"q-mb-sm cursor-pointer",on:{"click":function($event){return _vm.toLink(
                  'https://transparencia.municipalidaddearica.cl/page.php?p=85',
                  1
                )}}},[_vm._v(" Marco Normativo ")]),_c('li',{staticClass:"q-mb-sm cursor-pointer",on:{"click":function($event){return _vm.irPagina('plan-de-desarrollo-comunal-pladeco')}}},[_vm._v(" PLADECO ")]),_c('li',{staticClass:"q-mb-sm cursor-pointer",on:{"click":function($event){return _vm.toLink(
                  'https://docs.muniarica.cl/web/media/documentos/2023/02/55724-organigrama-ima-2019.pdf',
                  1
                )}}},[_vm._v(" Organigrama ")]),_c('li',{staticClass:"q-mb-sm cursor-pointer",on:{"click":function($event){return _vm.toLink(
                  'https://transparencia.municipalidaddearica.cl/page.php?p=11',
                  1
                )}}},[_vm._v(" Cuentas públicas ")]),_c('li',{staticClass:"q-mb-sm cursor-pointer",on:{"click":function($event){return _vm.toLink('https://imaarica.cl/PlanoRegulador/', 1)}}},[_vm._v(" Plan regulador ")])])])]),_c('div',{staticClass:"col-xs-12 col-sm-6 col-md-3 q-pa-sm"},[_c('div',{staticClass:"col-12"},[_c('p',{staticClass:"text-bold text-titulo-footer"},[_vm._v("SERVICIOS MUNICIPALES")]),_c('ul',[_c('li',{staticClass:"q-mb-sm cursor-pointer",on:{"click":function($event){return _vm.toLink('https://imaarica.cl/ParqueCentenario/', 1)}}},[_vm._v(" Parque Centenario ")]),_c('li',{staticClass:"q-mb-sm cursor-pointer",on:{"click":function($event){return _vm.irPagina('ofertas-laborales-omil')}}},[_vm._v(" OMIL ")]),_c('li',{staticClass:"q-mb-sm cursor-pointer",on:{"click":function($event){return _vm.irPagina('direccion-de-desarrollo-comunitario-dideco')}}},[_vm._v(" DIDECO ")]),_c('li',{staticClass:"q-mb-sm cursor-pointer",on:{"click":function($event){return _vm.irPagina('direccion-municipal-de-cementerios-demuce')}}},[_vm._v(" Cementerio ")]),_c('li',{staticClass:"q-mb-sm cursor-pointer",on:{"click":function($event){return _vm.toLink('http://imaarica.cl/veterinaria/', 1)}}},[_vm._v(" Veterinaria municipal ")]),_c('li',{staticClass:"q-mb-sm cursor-pointer",on:{"click":function($event){return _vm.irPagina('departamento-deportes-y-recreacion')}}},[_vm._v(" Deportes ")]),_c('li',{staticClass:"q-mb-sm cursor-pointer",on:{"click":function($event){return _vm.toLink(
                  'https://apsmuniarica.cl/web/farmacia-municipal-disam/',
                  1
                )}}},[_vm._v(" Farmacia municipal ")]),_c('li',{staticClass:"q-mb-sm cursor-pointer",on:{"click":function($event){return _vm.toLink('https://www.muniarica.cl/tramites-municipales', 1)}}},[_vm._v(" Trámites en línea ")]),_c('li',{staticClass:"q-mb-sm cursor-pointer",on:{"click":function($event){return _vm.irPagina('retiro-de-vehiculos')}}},[_vm._v(" Retiro de vehículos ")]),_c('li',{staticClass:"q-mb-sm cursor-pointer",on:{"click":function($event){return _vm.irPagina('retiro-de-residuos-domiciliarios')}}},[_vm._v(" Retiro de residuos domiciliarios ")])])])]),_c('div',{staticClass:"col-xs-12 col-sm-6 col-md-3 q-pa-sm"},[_c('div',{staticClass:"col-12"},[_c('p',{staticClass:"text-bold text-titulo-footer"},[_vm._v("ACCESOS IMA")]),_c('ul',[_c('li',{staticClass:"q-mb-sm cursor-pointer",on:{"click":function($event){return _vm.toLink('https://intranet.imaarica.cl/', 1)}}},[_vm._v(" Intranet Municipal ")]),_c('li',{staticClass:"q-mb-sm cursor-pointer",on:{"click":function($event){return _vm.toLink(
                  'https://intranetarica.smc.cl/login.aspx?ReturnUrl=%2f',
                  1
                )}}},[_vm._v(" Intranet RRHH ")]),_c('li',{staticClass:"q-mb-sm cursor-pointer",on:{"click":function($event){return _vm.toLink('https://www.google.com/intl/es-419/gmail/about/', 1)}}},[_vm._v(" Webmail ")]),_c('li',{staticClass:"q-mb-sm cursor-pointer",on:{"click":function($event){return _vm.toLink('https://muniarica.tchile.com/', 1)}}},[_vm._v(" Sistema Municipal de Iniciativas ")]),_c('li',{staticClass:"q-mb-sm cursor-pointer",on:{"click":function($event){return _vm.toLink(
                  'https://docs.google.com/spreadsheets/d/1XHrC621M20NIFHV0ESXu5NUhjK5dKM9pczQTIDaj2zg/edit?gid=0#gid=0/',
                  1
                )}}},[_vm._v(" Listado de Obras 2016-2024 ")])])])])]),_c('div',{staticClass:"row col-12 bg-footer"},[_c('div',{staticClass:"col-12"},[_vm._v(" © "+_vm._s(_vm.currentYear)+" Municipalidad de Arica - Todos los derechos reservados ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }